import * as React from 'react';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Hero from '../components/Hero';
import Products from '../components/Products';
import About from '../components/About';
import Contact from '../components/Contact';
import styled from 'styled-components';
import '../assets/css/main.css';
import { GlobalProvider } from '../context';

// markup
const IndexPage = () => {
  return (
    <GlobalProvider>
      <Main>
        <Layout>
          <Sidebar />
          <Hero />
          <Products />
          <About />
          <Contact />
        </Layout>
      </Main>
    </GlobalProvider>
  );
};

const Main = styled.main`
  width: 100%;
  position: relative;
`;

export default IndexPage;
