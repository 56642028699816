import React from 'react';
import styled from 'styled-components';
import bread from '../assets/images/bread_square.jpg';
import largeCinnamonRoll from '../assets/images/cinnamon_large_square.jpg';
import smallCinnamonRoll from '../assets/images/cinnamon_small_square.jpg';

const SingleProduct = () => {
  return (
    <Container>
      <div className="product-container">
        <img src={bread} alt="bread" className="product-image" />
        <h4>Sweet Bread</h4>
      </div>
      <div className="product-container">
        <img
          src={smallCinnamonRoll}
          alt="small cinnamon"
          className="product-image"
        />
        <h4>Cinnamon Rolls</h4>
        <p>(small)</p>
      </div>
      <div className="product-container">
        <img
          src={largeCinnamonRoll}
          alt="large cinnamon"
          className="product-image"
        />
        <h4>Cinnamon Rolls</h4>
        <p>(large)</p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);

  .product-container {
    width: 100%;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
  .product-image {
    width: 100%;
    object-fit: contain;
  }

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: none;
    column-gap: 4rem;
  }
`;

export default SingleProduct;
