import React from 'react';
import styled from 'styled-components';
import contact from '../assets/images/Contact.jpg';

const Contact = () => {
  return (
    <Container>
      <div className="section-center" id="contact">
        <div className="contact-content">
          <h2>Contact Our Bakery</h2>
          <div className="contact-info">
            <p>Email: hello@hello.com</p>
            <p>Tel: (801)123-4567</p>
          </div>
          <form className="contact-form">
            <div className="form-center">
              <h5>We want to hear from you</h5>
              <label htmlFor="name" className="contact-label">
                Name
              </label>
              <input
                type="text"
                name="name"
                className="contact-input name-input"
              />
              <label htmlFor="email" className="contact-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                className="contact-input email-input"
              />
              <label htmlFor="message" className="contact-label">
                Message
              </label>
              <textarea
                name="message"
                id=""
                cols="30"
                rows="10"
                className="contact-input message-input"
              ></textarea>
            </div>
            <div className="button-container">
              <button className="btn contact-btn">Submit</button>
            </div>
          </form>
        </div>
        <div className="contact-image-container">
          <img src={contact} alt="contact" className="contact-image" />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section`
  background: #fff;
  padding: 2.5rem 1rem;

  h2 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.25rem;
  }
  .contact-info {
    font-weight: 300;
    margin-bottom: 2rem;
  }
  .form-center {
    display: flex;
    flex-direction: column;
  }
  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .contact-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;
    font-weight: 200;
  }
  .contact-input {
    margin-bottom: 0.75rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }
  textarea,
  input {
    font-family: 'Nunito Sans', sans-serif;
    resize: none;
  }
  .message-input {
    margin-bottom: 2rem;
  }
  .button-container {
    margin-bottom: 4rem;
  }
  .contact-image {
    width: 100%;
  }

  @media (min-width: 992px) {
    margin-bottom: 4rem;

    .section-center {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2rem;
      align-items: center;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
    }
  }
`;

export default Contact;
