import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Container>
      <div className="footer-center">
        <p>
          Cinnamon Sweet &copy; {year} | Website created by{' '}
          <a href="https://www.joeyjenson.com/" className="website-link">
            Joey Jenson
          </a>
        </p>
      </div>
    </Container>
  );
};

const Container = styled.footer`
  display: flex;
  width: 100%;
  height: 4rem;
  background: black;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;

  .website-link {
    transition: 0.25s;
    color: #b7bd64;
    &:hover {
      transition: 0.25s;
      color: #f5fc86;
    }
  }
`;

export default Footer;
