import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'gatsby';
import { useGlobalContext } from '../context';

const Sidebar = () => {
  const { sidebarOpen, setSidebarOpen } = useGlobalContext();

  return (
    <Container>
      <aside className={sidebarOpen ? 'sidebar show-sidebar' : 'sidebar'}>
        <div className="sidebar-center">
          <header className="sidebar-header">
            <button
              className="close-icon"
              onClick={() => setSidebarOpen(false)}
            >
              <FaTimes />
            </button>
          </header>
          <ul className="sidebar-links">
            <li>
              <Link
                to="/"
                className="link"
                onClick={() => setSidebarOpen(false)}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/order"
                className="link"
                onClick={() => setSidebarOpen(false)}
              >
                Order
              </Link>
            </li>
            <li>
              <a
                href="#about"
                className="link"
                onClick={() => setSidebarOpen(false)}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="link"
                onClick={() => setSidebarOpen(false)}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </aside>
    </Container>
  );
};

const Container = styled.div`
  .sidebar-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: black;
    gap: 4rem;
  }
  .sidebar-header {
    height: 5rem;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 2rem;
  }
  .close-icon {
    color: white;
    background-color: transparent;
    border: none;
    svg {
      font-size: 2rem;
    }
  }
  .sidebar-links {
    width: 100%;
    height: 40%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    li {
      text-transform: none;
    }
  }
  .link {
    color: white;
    font-size: 1.5rem;
    font-weight: 300;
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;
export default Sidebar;
