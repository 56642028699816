import React from 'react';
import styled from 'styled-components';
import NavButton from './NavButton';
import NavLinks from './NavLinks';

const Navbar = () => {
  return (
    <Nav className="nav">
      <div className="nav-center">
        <NavLinks />
        <NavButton />
      </div>
    </Nav>
  );
};

const Nav = styled.nav`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: transparent;
  z-index: 10;

  .nav-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
`;

export default Navbar;
