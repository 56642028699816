import React from 'react';
import styled from 'styled-components';
import about from '../assets/images/About.jpg';

const About = () => {
  return (
    <Container id="about">
      <div className="section-center">
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores,
            eveniet. Odio ad ea quae eaque incidunt, possimus quis minus
            laudantium soluta animi consequatur. Voluptatibus labore doloribus
            nobis deserunt quia. Minus quidem atque porro doloremque quibusdam,
            cum illo dicta! Dolores error blanditiis officia eum enim assumenda
            dolor pariatur? Minus, omnis quos!
          </p>
          <div className="button-container">
            <button type="button" className="btn about-btn">
              Contact
            </button>
          </div>
        </div>
        <div className="about-image-container">
          <img src={about} alt="about" className="about-image" />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section`
  min-height: 70vh;
  background: #f6f6f6;
  color: #171717;
  padding: 2.5rem 1rem;

  .section-center {
    width: 100%;
  }
  .about-content {
    width: 100%;
    p {
      font-size: 1rem;
      margin: 1rem 0 2rem;
    }
  }
  .about-image {
    width: 100%;
  }
  .button-container {
    margin-bottom: 2rem;
  }

  @media (min-width: 992px) {
    min-height: 70vh;
    background: #f6f6f6;
    color: #171717;
    padding: 10rem 0;

    .section-center {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
    .about-content p {
      width: 80%;
      margin: 3rem 0;
    }
  }
`;

export default About;
