import React, { useState, useContext } from 'react';

const GlobalContext = React.createContext(null);

const GlobalProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <GlobalContext.Provider
      value={{ sidebarOpen, setSidebarOpen, handleSidebar }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
export { GlobalContext, GlobalProvider };
