import React from 'react';
import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { useGlobalContext } from '../context';

const NavButton = () => {
  const { setSidebarOpen } = useGlobalContext();

  return (
    <Container
      type="button"
      className="nav-toggle"
      onClick={() => setSidebarOpen(true)}
    >
      <FaBars />
    </Container>
  );
};

const Container = styled.button`
  background-color: transparent;
  border: transparent;
  color: #171717;

  .nav-toggle:hover {
    cursor: pointer;
  }
  svg {
    font-size: 2rem;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

export default NavButton;
