import React from 'react';
import styled from 'styled-components';
import SingleProduct from './SingleProduct';

const Products = () => {
  return (
    <Container>
      <div className="section-center">
        <header className="product-header">
          <h2>Fresh From Our Oven to Your Doorstep</h2>
        </header>
        <div className="product-content">
          <SingleProduct />
        </div>
        <div className="button-container">
          <button type="button" className="btn product-btn">
            Order Now
          </button>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.section`
  background: white;
  color: black;
  margin-bottom: 5rem;

  .section-center {
    width: 100%;
    padding: 0 1rem;
  }
  .product-header {
    margin: 2.5rem 0;
    text-align: center;
    h2 {
      font-size: 1.95rem;
    }
  }
  .product-content {
    padding: 0 2rem;
  }
  .button-container {
    margin-top: 2.5rem;
    text-align: center;
  }

  @media (min-width: 992px) {
    min-height: 70vh;

    .section-center {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }
    .product-header {
      margin: 5rem 0;
      text-align: center;
      h2 {
        font-size: 3rem;
      }
    }
    .product-content {
    }
    .button-container {
      margin-top: 5rem;
      text-align: center;
    }
  }
`;

export default Products;
