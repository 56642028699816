import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const NavLinks = () => {
  return (
    <Container className="nav-links">
      <li>
        <Link to="/" className="link">
          Home
        </Link>
      </li>
      <li>
        <Link to="/order" className="link">
          Order
        </Link>
      </li>
      <li>
        <a href="#about" className="link">
          About
        </a>
      </li>
      <li>
        <a href="#contact" className="link">
          Contact
        </a>
      </li>
    </Container>
  );
};

const Container = styled.ul`
  display: none;

  @media (min-width: 992px) {
    width: 100%;
    display: flex;
    justify-content: center;

    li {
      list-style-type: none;
      text-decoration: none;
      margin: 0 5rem;
    }
    .link h1 {
      color: white;
      text-decoration: none;
    }
    .link {
      text-transform: uppercase;
      text-decoration: none;
      color: #171717;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
      &:hover {
        cursor: pointer;
        color: #b9b9b9;
      }
    }
  }
`;

export default NavLinks;
