import React from 'react';
import styled from 'styled-components';
import hero from '../assets/images/Hero2.jpg';
import mobileHero from '../assets/images/Hero_mobile.jpg';
import { FaChevronDown } from 'react-icons/fa';

const Hero = () => {
  return (
    <Container className="section-center">
      <img src={hero} alt="hero image" className="hero-image" />
      <img src={mobileHero} alt="hero image" className="mobile-hero-image" />
      <div className="content">
        <h1 className="hero-title">Cinnamon Sweet.</h1>
        <h3 className="hero-subtitle">It's the Simple Pleasures in Life</h3>
        <button className="btn hero-btn">Order Now</button>
      </div>
      <span className="scrolldown-icon">
        <FaChevronDown />
      </span>
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  place-items: center;
  text-align: center;

  .hero-title {
    font-size: 4rem;
  }
  .hero-subtitle {
    text-transform: none;
    font-size: 1.25rem;
  }
  .hero-btn {
    margin-top: 1rem;
  }
  .hero-btn,
  .scrolldown-icon {
    cursor: pointer;
    svg {
      font-size: 2.5rem;
    }
  }
  .scrolldown-icon {
    position: absolute;
    bottom: 2rem;
  }
  .hero-image {
    display: none;
  }
  .mobile-hero-image {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 0.5;
    z-index: -1;
  }
  .content {
    padding-bottom: 6rem;
  }

  @media (min-width: 992px) {
    place-items: center;
    height: 99vh;
    overflow: hidden;

    .hero-image {
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      opacity: 0.5;
      z-index: -1;
    }
    .mobile-hero-image {
      display: none;
    }
    .content {
      padding-bottom: 6rem;
    }
    .hero-title {
      font-size: 8rem;
    }
    .hero-subtitle {
      text-transform: none;
    }
    .hero-btn {
      margin-top: 2rem;
      cursor: pointer;
      svg {
        font-size: 2.5rem;
      }
    }
  }
`;

export default Hero;
